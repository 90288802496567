<template>
  <section class="wrapper-main" style="padding-bottom: 3rem;">
    <div class="main">
      <el-image :src="src"></el-image>
      <el-card :class="[{'box-card': true}, {'fadeInUp': isMain}]" shadow="never">
        <strong class="card-title">{{ mainData.title }}</strong>
        <div class="card-main">{{ mainData.main }}</div>
        <div class="card-link">
          <router-link v-for="(item, index) in mainData.link" :key="index" :to="{path: item.url, query: {id: item.id}}">
            <span>{{ item.title }}</span>
            <el-image :src="item.src">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </router-link>
        </div>
      </el-card>
      <el-image :src="eLogo" class="eLogo"></el-image>
    </div>
    <!-- <div class="banerImg container-wapper" style="margin-top: 2rem;">
      <el-image :src="imgUrl" style="width: 8rem;"></el-image>
    </div> -->
    <div class="business container-wapper">
      <el-card :class="[[{'box-card': true}]]" shadow="never">
        <div class="title">
          <strong>业务领域<i class="line"></i></strong>
          <span>全方面服务于新型电力系统的建设</span>
        </div>
        <el-carousel :interval="3000" arrow="always" indicator-position="outside">
          <el-carousel-item v-for="(item, index) in carouselData" :key="index">
            <div class="carousel-link">
              <el-row :gutter="30">
                <el-col v-for="(imgList, i) in item" :key="i" :span="8">
                  <router-link :to="{path: imgList.link}" class="links">
                    <el-image :src="imgList.src">
                      <div slot="placeholder" class="image-slot">
                        加载中<span class="dot">...</span>
                      </div>
                    </el-image>
                    <div class="link-title">{{ imgList.title }}</div>
                  </router-link>
                </el-col>
              </el-row>
            </div>
          </el-carousel-item>
        </el-carousel>
      </el-card>
    </div>
    <div class="dynamic container-wapper">
      <el-card class="box-card" shadow="never">
        <div class="title">
          <strong>最新动态<i class="line"></i></strong>
          <span>关注最新资讯，了解旋荣动态</span>
        </div>
        <div>
          <el-row :gutter="30">
            <el-col :span="12">
              <ul :class="[{'dynamic-list' : true}, {'fadeInLeft': isShow}]">
                <el-carousel :height="carouseHeight + 'px'" direction="vertical" :interval="5000" arrow="always" @change="handleDynamicChange">
                <!-- <el-carousel :height="auto" direction="vertical" :interval="5000" arrow="always"> -->
                  <el-carousel-item v-for="(org, i) in dynamicDate" :key="i">
                    <!-- <h3 class="medium">{{ item }}</h3> -->
                    <li v-for="(item, index) in org" :key="index" class="dynamic-details">
                      <div class="dynamicTitle clearfix">
                        <div class="dynamicTime">
                          <strong>{{ new Date(item.date).format('MM-DD') }}</strong>
                          <p>{{ item.date }}</p>
                        </div>
                        <strong>{{ item.title }}</strong>
                      </div>
                      <p class="dynamic-content">{{ item.content }}</p>
                      <router-link :to="{path: item.link, query: {id: item.id}}">了解更多</router-link>
                    </li>
                  </el-carousel-item>
                </el-carousel>
                
                <!-- <h3 class="medium">{{ item }}</h3> -->
                <!-- <li v-for="(item, index) in dynamicDate" :key="index" class="dynamic-details">
                  <div class="dynamicTitle clearfix">
                    <div class="dynamicTime">
                      <strong>{{ new Date(item.time).format('MM-DD') }}</strong>
                      <p>{{ item.time }}</p>
                    </div>
                    <strong>{{ item.title }}</strong>
                  </div>
                  <p class="dynamic-content">{{ item.content }}</p>
                  <router-link :to="item.url">了解更多</router-link>
                </li> -->
              </ul>
            </el-col>
            <el-col :span="12">
              <el-image :src="dynamicSrc" :class="[{'transform-img': true}, {'fadeInRight': isShow}]" lazy>
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </div>
    <div class="grow container-wapper">
      <el-card class="box-card" shadow="never">
        <div class="title">
          <strong>成长轨迹<i class="line"></i></strong>
          <span>不积跬步，无以至千里；不积小流，无以成江海</span>
        </div>
        <div class="grow-box">
          <ul class="grow-list" ref="growList" :style="{width: ulwidth + 'px', transform: 'translate(' + liWidth + 'px, 0)'}">
            <li v-for="(item, index) in growData" :key="index" :class="index % 2 == 0 ? 'grow-list-item' : 'grow-list-item grow-list-item-odd'">
              <i class="grow-item-circular"></i>
              <span class="grow-item-time">{{ item.time }}</span>
              <i class="grow-item-line"></i>
              <div class="grow-item-content">
                <p v-for="(org, i) in item.content" :key="i"><span class="grow-dian">•</span>{{ org }}</p>
              </div>
            </li>
          </ul>
          <i class="el-icon-arrow-left prev" @click="handlePrevClick"></i>
          <i class="el-icon-arrow-right next" @click="handleNextClick"></i>
        </div>
      </el-card>
    </div>
    <div class="cooperation container-wapper">
      <el-card class="box-card" shadow="never">
        <div class="title">
          <strong>合作伙伴<i class="line"></i></strong>
          <span>结伴前行，合作共赢</span>
        </div>
        <div>
          <el-row :gutter="30">
            <el-col :span="10">
              <el-image :src="cooperationSrc1" :class="[{'transform-img': true, 'fadeInLeft': isCooperation}]" lazy>
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
            </el-col>
            <el-col :span="14">
              <el-image :class="[{'fadeInRight': isCooperation}]" :src="cooperationSrc2" lazy>
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      isMain: false,
      isShow: false,
      isCooperation: false,
      ulwidth: 0,
      liWidth: 0,
      carouseHeight: 0,
      eLogo: require('../assets/images/common/logo.png'),
      src: require('../assets/images/2023/主图.png'),
      imgUrl: require('../assets/images/2023/news/配图.png'),
      mainData: {
        title: 'Promise For Need',
        main: '旋荣科技秉承“您的需要，我们承诺”（Promise For Need）的经营理念，始终以客户需求和行业发展为导向，为客户提供高水平的智能技术研发，系统集成，设备运维和供应链服务。',
        link: [
          {url: '/profile', id: 7, title: '关于旋荣', src: require('../assets/images/2023/关于旋荣.png')},
          {url: '/develop', id: 1, title: '可持续发展', src: require('../assets/images/2023/可持续发展.png')}
        ]
      },
      carouselData: [
        [
          {link: '/platform?id=1', title: '主网范畴', src: require('../assets/images/2023/主网范畴.png'),},
          {link: '/dms?id=1', title: '配网范畴', src: require('../assets/images/2023/配网范畴.png'),},
          {link: '/monitor?id=1', title: '线路范畴', src: require('../assets/images/2023/线路范畴.png'),},
        ],
        [
          {link: '/overhaul?id=1', title: '工程大修', src: require('../assets/images/2023/工程大修.png'),},
          {link: '/zhulifei', title: '智慧供应链', src: require('../assets/images/2023/业务领域-03.png'),},
          {link: '/monitor?id=2', title: '虚拟电网', src: require('../assets/images/2023/虚拟电网.png'),},
        ],
      ],
      dynamicDate: [
        [
          { id : 1, link: '/newDetails', url : require('../assets/images/news/company1.png'), title : '公司监测系统升级', content : '近期，公司技术人员为上海特高压变电站升级SF6（六氟化硫）气体微水压力在线监测系统SF6气体以其优异的绝缘和灭弧性能，在电力系统中获得了广泛应用…', date : '2022-01-11'},
          { id : 2, link: '/newDetails', url : require('../assets/images/news/company2.png'), title : '第三代助力飞', content : '近期，旋荣科技发布第三代“助力飞”现代智慧供应链SaaS平台，这标志着“助力飞”的功能又登上一个新的台阶。新版“助力飞”是为服务商/电商企业的内部业务管理…', date : '2021-07-09'},
        ],
        [
          { id : 3, link: '/newDetails', url : require('../assets/images/news/company3.png'), title : '先进基层党组织', content : '近期，上海科技京城党委召开“两优一先”表彰大会，授予上海旋荣科技股份有限公司党支部“先进基层党支部”荣誉称号，同时，授予公司党支部书记吴晓春先生…', date : '2021-06-10'},
          { id : 4, link: '/newDetails', url : require('../assets/images/news/company4.png'), title : '子公司荣获高新证书', content : '近期，旋荣科技的全资子公司上海旋胜科技有限公司取得《高新技术企业证书》，有效期三年，公司将在税收、人才引进等方面享受各项优惠政策…', date : '2022-02-22'},
        ],
        [
          { id : 5, link: '/newDetails', url : require('../assets/images/news/company5.png'), title : '视觉AI精确识别项目全面展开', content : '2022年5月，公司的视觉AI精确识别项目全面展开，利用AI图像增强网络、多尺度检测模型等先进技术，有效提高客户的物资管理效率。经过我司多年的积累和与中科院等知名高校…', date : '2022-05-08'},
          { id : 6, link: '/newDetails', url : require('../assets/images/news/company6.png'), title : '助力飞“项目管理”', content : '2022年6月，“助力飞”现代智慧供应链SaaS平台的“项目管理”模块上线，本模块可以清晰地展现各个项目的进展情况，便于各级管理层对项目进行实时跟踪，并且…', date : '2022-06-08'},
        ],
      ],
      growData: [
        {time: 2023, content: [
          '2023年2月，公司荣获“海旅之星”荣誉称号；',
          '2023年3月，公司荣获“上海市专精特新中小企业”荣誉称号。',
          '2023年3月，公司取得“一种提高RFID标签系统安全性的方法”发明专利。',
        ]},
        {time: 2022, content: [
          '2022年8月，公司加入上海市电力工程协会，在协会大力支持下，新项目陆续展开。',
          '2022年9月，经全国股转公司批准，公司所属行业变更为“信息传输、软件和信息技术服务业”，这对公司发展具有里程碑的意义，标志着公司成功转型为以研发，生产，销售一体化企业。',
          '2022年11月，公司取得CMMi（能力成熟度模型）3级认证证书。',
        ]},
        {time: 2021, content: [
          '2021年11月，公司主导进行了业务相关的行业标准、团体标准的制订。',
          '2021年6月，助力飞5G上线——新增电子商城板块，并开始着力研发政府推广版本（精简版），使得助力飞商业模式更加完善。',
          '2021年5月，公司搬迁至自有资金购置的位于上海市延安东路 175 号旺角广场的新办公地点。',
          '2021年12月，公司的全资子公司“上海旋胜科技有限公司”取得高新技术企业证书。',
        ]},
        {time: 2020, content: [
          '2020年2月，2020年初疫情肆虐，公司筹集了数千个口罩捐赠给南京东路街道、南京东路派出所和武汉幸福社区工作人员等单位，藉此向所有抗疫一线的工作人员致敬。',
          '2020年3月，核心员工认定，并进行首轮的员工股权激励。',
          '2020年5月，升级为新三板创新层企业。',
          '2020年7月，助力飞4G在同行业中进行推广，取得良好的效果，同时根据用户的新增需求，不断丰富助力飞的功能。',
        ]},
        {time: 2019, content: [
          '2019年3月，深圳分公司成立，拓展南方市场业务，对接深圳研发的合作伙伴。',
          '2019年10月，山东分公司成立，拓展山东市场。',
          '2019年11月，助力飞4G上线：“企业版拼多多”模式和基于共享商城模式上线，进一步丰富助力飞的供应链上下游赋能功能并对商业模式进行进一步验证。',
        ]},
        {time: 2018, content: [
          '2018年5月，设立全资子公司上海旋胜科技有限公司，设立。',
          '2018年7月，上海旋荣科技股份有限公司党支部成立。',
          '2018年11月，上海旋荣科技股份有限公司工会成立。',
        ]},
        {time: 2017, content: [
          '2017年4月，南京分部作为公司的辅助研发中心正式成立，。',
          '2017年12月，票秘书电子发票开票APP上线。',
          '2017年12月，助力飞3G上线：并升级为SaaS架构，着力完善运营协同板块，新增CRM板块。',
        ]},
        {time: 2016, content: [
          '2016年1月，完成股份制改革，更名为上海旋荣科技股份有限公司。',
          '2016年8月，挂牌新三板，证券代码：838956。',
          '2016年8月，参与央企生产类物资电商项目履约。',
          '2016年9月，电子发票系统成功上线。',
        ]},
        {time: 2015, content: [
          '2015年2月，助力飞G2上线，整体转型为B/S架构，增加商品比价和毛利审批等辅助决策功能。',
          '2015年3月，经销商发票监管软件开发完成正式上线，首批上线的包括长安福特、上汽大众、捷豹路虎等知名汽车厂商。',
        ]},
        {time: 2014, content: [
          '2014年4月，参加央企的电商化项目的电商平台的研发。',
          '2014年6月，参与央企办公类物资电商化项目的业务履约。',
        ]},
        {time: 2013, content: [
          '2013年4月，公司注册成立。',
          '2013年8月，《发票数据管理软件》系列产品研发面世，服务央企，服务中小企业。',
          '2013年10月，助力飞第一代（助力飞G1）产品面世，C/S架构，主要用于公司内部管理，以进销存的功能为主。',
        ]},
      ],
      dynamicSrc: require('../assets/images/2023/最新动态0.png'),
      cooperationSrc1: require('../assets/images/2023/合作伙伴.png'),
      cooperationSrc2: require('../assets/images/2023/合作LOGO.png'),
    }
  },
  created () {
    this.ulwidth = this.growData.length * 300
    this.setCarouseHeight()
  },
  methods: {
    setCarouseHeight () {
      this.carouseHeight = (document.documentElement || document.body).clientWidth * .8 * .5 * 1199 / 1600
    },
    handlePrevClick () {
      if (this.liWidth == 0) return
      this.liWidth += 300
    },
    handleNextClick () {
      let box = document.querySelector('.grow-box')
      let w = box.clientWidth
      let n = Math.floor(w / 300)
      if ((Math.abs(this.liWidth) + n * 300) >= this.ulwidth) return
      this.liWidth -= 300
    },
    handleDynamicChange (index) {
      this.dynamicSrc = require('../assets/images/2023/最新动态' + index + '.png')
    },
    isInViewPort(el){
        //方法1
        const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        const offsetTop = el.offsetTop;
        const scollTop = document.documentElement.scrollTop;
        return offsetTop-scollTop <= viewPortHeight
    },
    cooperationShow () {
        let el = document.querySelector('.cooperation')
        let a = this.isInViewPort(el)
        let b = this.isInViewPort(document.querySelector('.dynamic-list'))
        if (!this.isCooperation) this.isCooperation = a
        if (!this.isShow) this.isShow = b
    }
  },
  mounted () {
    window.addEventListener("resize", this.setCarouseHeight)
    window.addEventListener('load', () => {
      this.isMain = true
      if (document.documentElement.scrollTop > 0) this.cooperationShow()
    })
    window.addEventListener('scroll', this.cooperationShow)
  },
  beforeDestroy () {
    window.removeEventListener("resize", this.setCarouseHeight)
    window.removeEventListener("scroll", this.cooperationShow)
  }
}
</script>

<style scoped>
.container-wapper {padding: 1rem 5%;}
.box-card {border: none; text-align: left; background-color: transparent; overflow: inherit;}
.el-image {width: 100%;}
.title {border-bottom: 1px solid #666; display: inline-block; margin-bottom: 2rem; position: relative;}
.title strong {font-size: 2rem; margin-right: 1rem;}
.title .line {position: absolute; left: 0; bottom: -2px; width: 4em; height: 3px; background-color: #666;}
.transform-img {transform: translate(0, 4rem)}
.box-card /deep/ .el-carousel__indicators--outside button, .box-card /deep/.el-carousel__button {background-color: #e67700;}





.main {position: relative;}
.main .el-image {width: 100%;}
.main  .eLogo {position: absolute; width: 15%; z-index: 2; left: 70%; top: 46%; animation: rightToLeft 2s ease-in-out 2s backwards;}
.main .box-card {position: absolute; left: 30%; top: 70%; background-color: transparent; transform: translate(-50%, -50%); width: 40%; color: #666; padding: 1rem 0;}
.main .box-card .card-title {font-size: 2.4rem; color: #666;}
.main .box-card .card-main {width: 80%; margin: 1rem 0 1rem; font-size: .8rem; line-height: 2}
.main .box-card .card-link a {box-sizing: border-box; display: inline-block; width: 45%; margin-right: 5%; background-color: rgba(255, 255, 255, .7); padding: .6rem; color: #666; font-size: .6rem;}
.main .box-card .card-link a .el-image {margin-top: .5rem;}
.main .box-card .card-link a .el-image:hover {transform: scale(1.06, 1.06)}

.business {margin-top: 5rem; position: relative;}
.business .carousel-link {text-align: center;}
.business .carousel-link .links {position: relative; width: 100%;}
.business .carousel-link .links .link-title {position: absolute; bottom: 8%; left: 50%; transform: translate(-50%, 0); color: #fff; font-size: 1rem;}
.business .carousel-link .links:hover .link-title {font-size: 1.6rem;}
.business /deep/ .el-carousel__container {height: calc(30vw / 1600 * 1143 );}


.cooperation, .dynamic {background-color: #eef2f5; position: relative;}
.cooperation .el-image {opacity: 0;}

.grow {margin-top: 4rem; position: relative;}
.dynamic .dynamic-list {padding: 2rem 0 0 12%; opacity: 0;}
.dynamic .dynamic-list .el-carousel--vertical {padding: 0 2rem}
.dynamic .dynamic-list .dynamic-details {padding: 2rem 1rem; border-bottom: 1px solid #666;}
.dynamic .dynamic-list .dynamic-details:last-child {border: none; padding-bottom: 0;}
.dynamic .dynamic-list .dynamic-details strong {font-size: 1.4rem; padding: .5rem 0; display: inline-block;}
.dynamic .dynamic-list .dynamic-details .dynamicTitle {display: flex;}
.dynamic .dynamic-list .dynamic-details .dynamicTime {display: inline-block; text-align: left; border-bottom: 2px solid #e67700; padding: .5rem 0; min-width: 6em; margin-right: 2em;}
.dynamic .dynamic-list .dynamic-details .dynamicTime strong {display: inline-block; font-size: 2rem; padding: 0;}
.dynamic .dynamic-list .dynamic-details .dynamic-content {margin: 1rem 0; line-height: 2; font-size: .8rem;}
.dynamic .dynamic-list .dynamic-details a {display: inline-block; padding: .4rem 1rem; border: 1px solid #666; border-radius: 2rem; color: #e67700; font-size: .8rem;}
.dynamic .transform-img[data-v-a83bd3b0] {transform: translate(0, 5rem);}


.el-step:nth-child(2n) .el-step__title.is-finish {transform: translate(0, -3rem);}

/* .grow {position: relative;} */
.grow-box {width: 100%; padding: 10px 0; box-sizing: border-box; overflow: hidden; position: relative;}
.grow-list {height: 500px; box-sizing: border-box; min-width: 400vw; max-width: 800vw;}
.grow-list-item {display: inline-block; box-sizing: border-box; position: relative; height: 240px; border-bottom: 2px solid #ccc; min-width: 300px;}
.grow-item-circular {position: absolute; width: 8px; height: 8px; bottom: 0; left: 50%; border: 1px solid #e67700; border-radius: 50%; transform: translate(-50%, 50%); background-color: #fff;}
.grow-item-time {position: absolute; bottom: 0px; left: 50%; transform: translate(-50%, 200%); color: #e67700; font-weight: 600;;}
.grow-list-item-odd .grow-item-time {transform: translate(-50%, -50%); color: #e67700;}
.grow-item-line {position: absolute; bottom: 0px; left: 50%; width: 1px; height: 30px; color: #e67700; transform: translate(-50%, -10%); z-index: 1; background-color: #e67700;}
.grow-list-item-odd .grow-item-line {transform: translate(-50%, 110%);}
.grow-item-content {position: absolute; bottom: 40px; left: 50%; transform: translate(-50%, 0%); box-sizing: border-box; width: 100%; max-height: 208px; overflow: hidden; line-height: 1.6;}
.grow-list-item-odd .grow-item-content {transform: translate(-50%, 100%); bottom: -40px;}
.grow-item-content p {text-align: left; font-size: .6rem;}
.grow .grow-dian {color: #e67700; margin-right: 1em;}
.grow .prev, .grow .next {position: absolute; top: 48%; transform: translate(-50%, -50%); border: 1px solid #ccc; border-radius: 50%; padding: .8rem; font-size: 2rem; z-index: 2; background-color: #fff;}
.grow .prev:hover, .grow .next:hover {color: #e67700;}
.grow .prev {left: 1.9rem;}
.grow .next {right: -1.9rem;}



.wrapper-main .fadeInUp {animation-name: fadeInUp; animation-duration: 2s; opacity: 1;}
@keyframes fadeInUp {
  0% {opacity: 0; top: 100%;}
  100% {opacity: 1;top: 70% }
}
.wrapper-main .fadeInLeft {animation-name: fadeInLeft; animation-duration: 4s; opacity: 1;}
@keyframes fadeInLeft {
  0% {opacity: 0; transform: translate(-100%, 4rem);}
  100% {opacity: 1; transform: translate(0, 4rem); }
}
.wrapper-main .fadeInRight {animation-name: fadeInRight; animation-duration: 4s; opacity: 1;}
@keyframes fadeInRight {
  0% {opacity: 0; transform: translate(100%, 0);}
  100% {opacity: 1; transform: translate(0, 0); }
}
</style>
